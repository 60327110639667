import { Outlet } from "react-router-dom";
import Header from "../component/Header/Header";
import LeftMenu from "../component/LeftMenu/LeftMenu";
import { useEffect } from "react";
import React from "react";

const PrivateRoute = () => {
  useEffect(() => {
    document.body.classList.add("layout-1");

    return function cleanup() {
      document.body.classList.remove("layout-1");
    };
  }, []);
  return (
    <>
     <div className="sidebar p-2 py-md-3">
        <LeftMenu />
      </div>

      <div className="wrapper">
            <header className="page-header sticky-top px-xl-4 px-sm-2 px-0 py-lg-2 py-1">
              <Header />
            </header>
        <Outlet />
        </div>
     

      {/* <BreadCrumbs/> */}
    </>
  );
};

export default PrivateRoute;
