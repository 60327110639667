import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { addContact } from "../../services/contactService";
import { errorToast, successToast } from "../Toasts/Toasts";

const ContactAddModal = ({ show, handleClose }) => {
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      available: false,
    },
  });

  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [contactType, setContactType] = useState("");
  const [source, setSource] = useState("");
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      const response = await addContact(data);

      if (response) {
        successToast(response?.message);
        handleClear();

        navigate("/contact");
        handleClose();
      }
    } catch (error) {
      errorToast(error?.response?.data?.message || "Something went wrong.");
    }
  };

  const handleInputChange = (fieldName, value) => {
    setValue(fieldName, value);
    if (value) {
      clearErrors(fieldName);
    }
  };

  const handleClear = () => {
    setLocation("");
    setName("");
    setContactNo("");
    setCompanyName("");
    setContactType("");
    setSource("");
    reset();
  };

  const handleSaveContact = (data) => onSubmit(data);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add New Contact</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(handleSaveContact)}>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 mb-3">
              <label>
                Name <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Name"
                {...register("name", { required: "Required" })}
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  handleInputChange("name", e.target.value);
                }}
              />
              {errors.name && <p className="text-danger">{errors.name.message}</p>}
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12 mb-3">
              <label>
                Location <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Location"
                {...register("location", { required: "Required" })}
                value={location}
                onChange={(e) => {
                  setLocation(e.target.value);
                  handleInputChange("location", e.target.value);
                }}
              />
              {errors.location && <p className="text-danger">{errors.location.message}</p>}
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12 mb-3">
              <label>
                Contact No <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Contact Number"
                value={contactNo}
                {...register("contactNo", { required: "Required" })}
                maxLength={10}
                onChange={(e) => {
                  setContactNo(e.target.value.trim());
                  handleInputChange("contactNo", e.target.value);
                }}
              />
              {errors.contactNo && <p className="text-danger">{errors.contactNo.message}</p>}
            </div>

            <div className="col-lg-6 col-md-8 col-sm-12 mb-6">
                      <label>
                        Contact Type <span className="text-danger">*</span>
                      </label>
                      <select className="form-control" {...register("contactType", { required: "Required" })}>
                        <option value="">Select contact type</option>
                        <option value="Company">Company</option>
                        <option value="Contractor">Contractor</option>
                        <option value="Event planner">Event planner</option>
                        <option value="Individual">Individual</option>
                        <option value="Landscape Architect">Landscape Architect</option>
                        <option value="NGO">NGO</option>
                        <option value="Nursery">Nursery</option>
                      </select>
                      {errors.contactType && <p className="text-danger">{errors.contactType.message}</p>}
            </div>

            <div className="col-lg-6 col-md-8 col-sm-12 mb-6">
                      <label>
                        Contact Source <span className="text-danger">*</span>
                      </label>
                      <select className="form-control" {...register("source", { required: "Required" })}>
                        <option value="">Select contact source</option>
                        <option value="Website">Website</option>
                        <option value="Indiamart">Indiamart</option>
                        <option value="JustDIal">JustDIal</option>
                        <option value="Data Mining - Google">Data Mining - Google</option>
                        <option value="Data Mining - Other">Data Mining - Other</option>
                        <option value="Other">Other</option>
                      </select>
                      {errors.source && <p className="text-danger">{errors.source.message}</p>}
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12 mb-3">
              <label>
                Company Name
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Company Name"
                value={companyName}
                onChange={(e) => {
                  setCompanyName(e.target.value);
                  handleInputChange("companyName", e.target.value);
                }}
              />
              {errors.companyName && <p className="text-danger">{errors.companyName.message}</p>}
            </div>

            <div className="col-12 d-flex justify-content-end">
              <Button type="submit" variant="outline-success btn-sm" className="me-2" onClick={handleSubmit(handleSaveContact)}>
                Save
              </Button>
              <Button variant="secondary" onClick={handleClear}>
                Cancel
              </Button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ContactAddModal;
