import React from "react";
import { useForm } from "react-hook-form";
import { errorToast, successToast } from "../Toasts/Toasts";
import "./Login.css"; // Add your CSS file for custom styling
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLogin } from "../../redux/features/authSlice.js";
import { loginUser } from "../../services/userService.js";
import { Button } from "react-bootstrap";

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = async (data) => {



    try {
      const response = await loginUser(data);
      if (response?.success) {
        localStorage.setItem("token", response?.token);

        dispatch(
          setLogin({
            isAuthenticated: true,
          })
        );

        navigate("/product");
      } else {
        errorToast(response?.message);
      }
    } catch (error) {
      errorToast("Login failed. Please check your credentials.");
    }
  };

  return (
    <div className="login-container">
      <div className="login-card">
        <h2 className="login-heading">Login to Continue</h2>
        <form onSubmit={handleSubmit(onSubmit)} className="login-form">
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              className="form-control"
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: "Invalid email address",
                },
              })}
            />
            {errors.email && <p className="text-danger">{errors.email.message}</p>}
          </div>

          <div className="form-group">
            <label htmlFor="password">Password:</label>
            <input type="password" id="password" className="form-control" {...register("password", { required: "Password is required" })} />
            {errors.password && <p className="text-danger">{errors.password.message}</p>}
          </div>

          <Button type="submit" className="outline-success btn-sm">
            Login
          </Button>
        </form>
      </div>
    </div>
  );
};

export default Login;
