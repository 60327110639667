import { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { productFilter } from "../../services/productService";
import { errorToast } from "../Toasts/Toasts";

const ProductList = () => {
  const [name, setName] = useState("");
  const [alternativeName, setAlternativeName] = useState("");
  const [sku, setSKU] = useState("");
  const [height, setHeight] = useState("");
  const [category, setCategory] = useState("");
  const [productData, setProductData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    handleFilterData();
  }, [currentPage, pageSize]);

  const handleFilterData = useCallback(async () => {
    const filterParams = {
      ...(name?.trim() && { name: name.trim() }),
      ...(alternativeName?.trim() && { alternativeName: alternativeName.trim() }),
      ...(sku?.trim() && { sku: sku.trim() }),
      ...(height?.trim() && { height: parseInt(height) }),
      ...(category?.trim() && { category: category.trim() }),
    };

    try {
      setLoading(true);
      const apiResponse = await productFilter({
        ...filterParams,
        page: currentPage,
        size: pageSize,
      });

      setProductData(apiResponse?.rows || []);
      setTotalRecords(apiResponse?.count || 0);
    } catch (error) {
      errorToast("Something went wrong");
    } finally {
      setLoading(false);
    }
  }, [name, alternativeName, sku, height, category, currentPage, pageSize]);

  const handleFilterDataAndResetPage = useCallback(async () => {
    setCurrentPage(1); // Reset to page 1 when applying filters
    await handleFilterData();
  }, [handleFilterData]);

  const handleClear = async () => {
    setName("");
    setAlternativeName("");
    setSKU("");
    setHeight("");
    setCategory("");
    setCurrentPage(1); // Reset to page 1 when clearing filters

    try {
      setLoading(true);
      const apiResponse = await productFilter({
        page: currentPage,
        size: pageSize,
      });
      setProductData(apiResponse?.rows || []);
      setTotalRecords(apiResponse?.count || 0);
    } catch (error) {
      errorToast("Something went wrong");
    } finally {
      setLoading(false); // Set loading to false once data is fetched
    }
  };

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const totalPages = Math.ceil(totalRecords / pageSize);

  return (
    <div className="page-body px-0 py-lg-2 py-1 mt-0 mt-lg-3 ms-3">
      <div className="container-fluid p-0">
        <div className="row g-3">
          {/* Product Management header + Add button */}
          <div className="col-12">
            <div className="row-title px-2">
              <div>
                <h6 className="fw-bold">Product Management</h6>
              </div>
              <Link className="btn btn-outline-success  mb-3" to="/save-product">
                <i className="fa fa-plus" />
                Add
              </Link>
            </div>
          </div>

          {/* Dash Card 1 */}
          <div className="container">
            <div className="card">
              <div className="card-body">
                <form>
                  <div className="row">
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
                      <input type="text" className="form-control" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
                    </div>

                    <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
                      <input type="text" className="form-control" placeholder="Alternative Name" value={alternativeName} onChange={(e) => setAlternativeName(e.target.value)} />
                    </div>

                    <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
                      <input type="text" className="form-control" placeholder="SKU" value={sku} onChange={(e) => setSKU(e.target.value)} />
                    </div>

                    <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
                      <input type="text" className="form-control" placeholder="Height" value={height} onChange={(e) => setHeight(e.target.value)} />
                    </div>

                    <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
                      <select className="form-control" value={category} onChange={(e) => setCategory(e.target.value)}>
                        <option value="" disabled>
                          Select a category
                        </option>
                        <option value="plants">Plants</option>
                        <option value="pots">Pots</option>
                        <option value="gifting">Gifting</option>
                        <option value="lawngrass">Lawn Grass</option>
                      </select>
                    </div>

                    <div className="col-lg-3 col-md-4 col-sm-6 mb-3 d-flex justify-content-end">
                      <button type="button" onClick={handleFilterDataAndResetPage} className="btn btn-sm btn-light-success me-2">
                        Filter
                      </button>
                      <button type="button" onClick={handleClear} className="btn btn-light-secondary btn-sm">
                        Clear
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* Dash Card 2 - Product Table */}
        <div className="col-12 mt-1">
          <div className="card">
            <div className="card-body">
              <div id="DataTables_Table_2_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                <div className="row">
                  <div className="col-sm-12 px-0 table-responsive">
                    {loading ? (
                      <div className="d-flex justify-content-center align-items-center" style={{ height: "300px" }}>
                        <div className="spinner-border text-primary" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : productData.length === 0 ? (
                      <div className="d-flex justify-content-center align-items-center" style={{ height: "300px" }}>
                        <p>No records found</p>
                      </div>
                    ) : (
                      <table className="myDataTable table table-hover mb-0 nowrap dataTable no-footer dtr-inline" id="DataTables_Table_2">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Height (ft)</th>
                            <th>SKU</th>
                            <th>Colour</th>
                            <th>Category</th>
                            <th>Alternative</th>
                            <th>Retail Price</th>
                            <th>Available Names</th>
                            <th>MOQ1</th>
                            <th>MOQ1 Price</th>
                            <th>MOQ2</th>
                            <th>MOQ2 Price</th>
                            <th>MOQ3</th>
                            <th>MOQ3 Price</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {productData?.map((item) => (
                            <tr key={item.id}>
                              <td>{item.name}</td>
                              <td>{item.height}</td>
                              <td>{item.sku}</td>
                              <td>{item.colour}</td>
                              <td>{item.category}</td>
                              <td>{item.alternativeName}</td>
                              <td>{item.retailPrice}</td>
                              <td>{item.available ? "Yes" : "No"}</td>
                              <td>{item.moq1}</td>
                              <td>{item.moq1Price}</td>
                              <td>{item.moq2}</td>
                              <td>{item.moq2Price}</td>
                              <td>{item.moq3}</td>
                              <td>{item.moq3Price}</td>
                              <td>
                                <Link className="btn-sm btn-outline-success  mb-3" to={`/product/${item.id}`}>
                                  <i className="fa fa-edit" />
                                  Edit
                                </Link>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>

              {/* Pagination Controls */}
              <div className="d-flex justify-content-between mt-3">
                <button className="btn btn-light-secondary" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                  Previous
                </button>
                <span>
                  Page {currentPage} of {totalPages}
                </span>
                <button className="btn btn-light-secondary" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductList;
