import "./App.css";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import ProductAdd from "./component/Product/ProductAdd";
import ProductEdit from "./component/Product/ProductEdit";
import ProductList from "./component/Product/ProductList";
import PrivateRoute from "./routes/PrivateRoute";
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./component/Login/Login";
import VendorList from "./component/Vendor/VendorList";
import VendorAddPrices from "./component/Vendor/VendorPrices";
import ProductPriceList from "./component/Vendor/ProductPriceList";
import CustomerList from "./component/Customer/CustomerList";
import CustomerAddRequirement from "./component/Customer/CustomerRequirements";
import CustomerRequirementList from "./component/Customer/CustomerRequirementsList";
import ContactList from "./component/Contact/ContactList";

function App() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  return (
    <>
      <Routes>
        <Route element={<PrivateRoute />}>
          <Route path="/product" element={isAuthenticated ? <ProductList /> : <Navigate to="/" />} />
          <Route path="/save-product" element={isAuthenticated ? <ProductAdd /> : <Navigate to="/" />} />
          <Route path="/vendor" element={isAuthenticated ? <VendorList /> : <Navigate to="/" />} />
          <Route path="/product/:id" element={isAuthenticated ? <ProductEdit /> : <Navigate to="/" />} />
          <Route path="/vendor/prices/:vendorId" element={isAuthenticated ? <VendorAddPrices /> : <Navigate to="/" />} />
          <Route path="/vendor/prices" element={isAuthenticated ? <ProductPriceList /> : <Navigate to="/" />} />
          <Route path="/customer" element={isAuthenticated ? <CustomerList /> : <Navigate to="/" />} />
          <Route path="/customer/:customerId/requirement/:customerRequirementId" element={isAuthenticated ? <CustomerAddRequirement /> : <Navigate to="/" />} />
          <Route path="/customer/:customerId/requirement" element={isAuthenticated ? <CustomerAddRequirement /> : <Navigate to="/" />} />
          <Route path="/customerRequirements" element={isAuthenticated ? <CustomerRequirementList /> : <Navigate to="/" />} />
          <Route path="/contact" element={isAuthenticated ? <ContactList /> : <Navigate to="/" />} />
        </Route>

        <Route path="/" element={<Login />} />
      </Routes>
      <ToastContainer />
    </>
  );
}

export default App;
