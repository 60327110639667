import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { setLogout } from "../../redux/features/authSlice.js"; // Adjust the path as necessary
import HeaderToggleBtn from "./HeaderToggleBtn";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    // Dispatch the logout action
    dispatch(setLogout());

    // Clear local storage
    localStorage.clear();

    // Navigate to login page
    navigate("/");
  };

  return (
    <>
      <div className="container-fluid">
        <nav className="navbar py-0 d-flex justify-content-between">
          <div className="d-flex">
            {/* start: toggle btn */}
            <HeaderToggleBtn />
          </div>
          <div className="d-flex align-items-center">
            <span className="d-inline-block w-100 color-400" onClick={handleLogout}>
              <svg xmlns="http://www.w3.org/2000/svg" width={18} fill="currentColor" viewBox="0 0 16 16">
                <path d="M7.5 1v7h1V1h-1z" />
                <path className="fill-secondary" d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
              </svg>
            </span>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Header;
