import apiConstants from "../constants/apiConstants";
import { axios } from "../utils/axios";

const baseUrl = process.env.REACT_APP_API_HOST;


export const getContacts = async (filterObj) => {
  try {
    let url = `${baseUrl}/${apiConstants.GET_CONTACTS}`;

    const response = await axios.post(url, filterObj);

    return response.data;
  } catch (error) {
    throw error;
  }
};



export const addContact = async (data) => {
  try {
    const url = `${baseUrl}/${apiConstants.ADD_CONTACT}`;

    const response = await axios.post(url, data);

    return response;
  } catch (error) {
    throw error;
  }
};
