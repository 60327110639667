import apiConstants from "../constants/apiConstants";
import { axios } from "../utils/axios";

const baseUrl = process.env.REACT_APP_API_HOST;

export const loginUser = async (data) => {
  try {
    let url = `${baseUrl}/${apiConstants.LOGIN}`;

    const response = await axios.post(url, data);

    return response;
  } catch (error) {
    throw error;
  }
};

export const getMasterData = async (data) => {
  try {
    let url = `${baseUrl}/${apiConstants.GET_MASTER_DATA}`;

    const response = await axios.get(url);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCustomerMasterData = async (data) => {
  try {
    let url = `${baseUrl}/${apiConstants.GET_CUSTOMER_MASTER_DATA}`;

    const response = await axios.get(url);

    return response.data;
  } catch (error) {
    throw error;
  }
};