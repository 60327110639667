import apiConstants from "../constants/apiConstants";
import { axios } from "../utils/axios";

const baseUrl = process.env.REACT_APP_API_HOST;

export const getVendors = async (data) => {
  try {
    const { page, size } = data;
    const url = `${baseUrl}/${apiConstants.GET_VENDORS}?page=${page}&size=${size}`;

    const response = await axios.get(url, data);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addVendor = async (data) => {
  try {
    const url = `${baseUrl}/${apiConstants.GET_VENDORS}`;

    const response = await axios.post(url, data);

    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchSku = async (data) => {
  try {
    const { query } = data;

    const url = `${baseUrl}/${apiConstants.GET_SKU}?search=${query}`;

    const response = await axios.get(url);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addProductPrice = async (data) => {
  try {
    const url = `${baseUrl}/${apiConstants.ADD_PRODUCT_PRICE}`;

    const response = await axios.post(url, data);

    return response;
  } catch (error) {
    throw error;
  }
};
