const localStorageStore = {};

localStorageStore.getToken = () => {
  return localStorage.token ?? "";
};

localStorageStore.setToken = (token) => {
  localStorage.setItem("token", token);
};

export default localStorageStore;
