import { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { getVendors } from "../../services/vendorService";
import { errorToast } from "../Toasts/Toasts";
import { Button } from "react-bootstrap";
import VendorAddModal from "./VendorAddModal";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";

const VendorList = () => {
  const navigate = useNavigate();
  const [vendorData, setVendorData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    fetchVendorData();
  }, [currentPage, pageSize]);

  const fetchVendorData = useCallback(async () => {
    try {
      setLoading(true);
      const apiResponse = await getVendors({
        page: currentPage,
        size: pageSize,
      });

      setVendorData(apiResponse?.rows || []);
      setTotalRecords(apiResponse?.count || 0);
    } catch (error) {
      errorToast("Something went wrong");
    } finally {
      setLoading(false);
    }
  }, [currentPage, pageSize]);

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const totalPages = Math.ceil(totalRecords / pageSize);

  const handleAddPricesClick = (vendorId, vendorName, vendorLocation) => {
    navigate(`/vendor/prices/${vendorId}`, {
      state: {
        vendorName,
        vendorLocation,
      },
    });
  };

  return (
    <div className="page-body px-0 py-lg-2 py-1 mt-0 mt-lg-3 ms-3">
      <div className="container-fluid p-0">
        <div className="row g-3">
          {/* Vendor Management header + Add button */}
          <div className="col-12">
            <div className="row-title px-2">
              <div>
                <h6 className="fw-bold">Vendor Management</h6>
              </div>
              <Button variant="outline-success btn-sm" onClick={handleShowModal} className="mb-3">
                Add Vendor
              </Button>
              <VendorAddModal show={showModal} handleClose={handleCloseModal} />
            </div>
          </div>
        </div>

        {/* Vendor Table */}
        <div className="col-12 mt-1">
          <div className="card">
            <div className="card-body">
              <div id="DataTables_Table_2_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                <div className="row">
                  <div className="col-sm-12 px-0 table-responsive">
                    {loading ? (
                      <div className="d-flex justify-content-center align-items-center" style={{ height: "300px" }}>
                        <div className="spinner-border text-primary" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : vendorData.length === 0 ? (
                      <div className="d-flex justify-content-center align-items-center" style={{ height: "300px" }}>
                        <p>No records found</p>
                      </div>
                    ) : (
                      <table className="myDataTable table table-hover mb-0 nowrap dataTable no-footer dtr-inline" id="DataTables_Table_2">
                        <thead>
                          <tr>
                            <th>Vendor Code</th>
                            <th>Vendor Name</th>
                            <th>Location</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {vendorData?.map((item) => (
                            <tr key={item.id}>
                              <td>{item.vendorCode}</td>
                              <td>{item.name}</td>
                              <td>{item.location}</td>
                              <td>
                              
                                <FaPlus data-tip="Add Price" data-toggle="tooltip" data-placement="top" title="Add Price" onClick={() => handleAddPricesClick(item.id, item.name, item.location)} style={{ cursor: "pointer", color: "#007bff" }} color="#00ac9a" fill="#00ac9a" />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>

              {/* Pagination Controls */}
              <div className="d-flex justify-content-between mt-3">
                <button className="btn btn-light-secondary" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                  Previous
                </button>
                <span>
                  Page {currentPage} of {totalPages}
                </span>
                <button className="btn btn-light-secondary" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorList;
