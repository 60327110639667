import { useState, useEffect, useCallback } from "react";
import Select from "react-select"; 
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getProductsPrice } from "../../services/productService"; 
import { errorToast } from "../Toasts/Toasts";
import { getMasterData } from "../../services/userService";
// import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import DateRangePicker from "react-bootstrap-daterangepicker";

const ProductPriceList = () => {
  const [vendorId, setVendorId] = useState("");
  const [price, setPrice] = useState("");
  const [productSku, setProductSku] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [productData, setProductData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);
  const [vendorOptions, setVendorOptions] = useState([]); 

  useEffect(() => {
    handleFilterData();
    fetchVendorOptions();
  }, [currentPage, pageSize]);

  const fetchVendorOptions = useCallback(async () => {
    try {
      const response = await getMasterData();
      const options = response?.vendors?.map((vendor) => ({ value: vendor.id, label: vendor.name }));
      setVendorOptions(options);
    } catch (error) {
      errorToast("Failed to fetch vendors");
    }
  }, []);

  const handleFilterData = useCallback(async () => {
    const filterParams = {
      ...(vendorId && { vendorId }),
      ...(productSku?.trim() && { productSku: productSku.trim() }),
      ...(startDate && { startDate: startDate.toLocaleDateString() }),
      ...(endDate && { endDate: endDate.toLocaleDateString() }),
    };

    try {
      setLoading(true);
      const apiResponse = await getProductsPrice({
        ...filterParams,
        page: currentPage,
        size: pageSize,
      });

      setProductData(apiResponse?.records?.rows || []);
      setTotalRecords(apiResponse?.records?.count || 0);
    } catch (error) {
      errorToast("Something went wrong");
    } finally {
      setLoading(false);
    }
  }, [vendorId, productSku, startDate, endDate, currentPage, pageSize]);

  const handleFilterDataAndResetPage = useCallback(async () => {
    setCurrentPage(1);
    await handleFilterData();
  }, [handleFilterData]);

  const handleClear = async () => {
    setVendorId("");
    setPrice("");
    setProductSku("");
    setStartDate(null);
    setEndDate(null);

    try {
      setLoading(true);
      const apiResponse = await getProductsPrice({
        page: currentPage,
        size: pageSize,
      });

      setProductData(apiResponse?.records?.rows || []);
      setTotalRecords(apiResponse?.records?.count || 0);
    } catch (error) {
      errorToast("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const handleApply = (event, picker) => {
    setStartDate(picker.startDate.toDate());
    setEndDate(picker.endDate.toDate());
  };
  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const totalPages = Math.ceil(totalRecords / pageSize);

  return (
    <div className="page-body px-0 py-lg-2 py-1 mt-0 mt-lg-3 ms-3">
      <div className="container-fluid p-0">
        <div className="row g-3">
          <div className="col-12">
            <div className="row-title px-2">
              <div>
                <h6 className="fw-bold">Product Management</h6>
              </div>
            </div>
          </div>

          {/* Dash Card 1 */}
          <div className="container">
            <div className="card">
              <div className="card-body">
                <form>
                  <div className="row">
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
                    <select
                        className="form-control"
                        value={vendorId}
                        onChange={(e) => setVendorId(e.target.value)}
                      >
                        <option value="">Select Vendor</option>
                        {vendorOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="SKU"
                        value={productSku}
                        onChange={(e) => setProductSku(e.target.value)}
                      />
                    </div>

                    <div className="col-lg-3 col-md-2 col-sm-6 mb-3">
                    <DateRangePicker 
                     initialSettings={{   locale: { cancelLabel: "Clear", format: "DD-MM-YYYY" }}}
                    onApply={handleApply} >
                        <input
                        
                          type="text"
                          className="form-control"
                          placeholder="Select Date Range"
                          value={startDate && endDate ? `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}` : ""}
                        />
                      </DateRangePicker>
                      
                    </div>
                    

                    <div className="col-lg-3 col-md-4 col-sm-6 mt-2 ">
                      <button type="button" onClick={handleFilterDataAndResetPage} className="btn btn-sm btn-light-success me-2">
                        Filter
                      </button>
                      <button type="button" onClick={handleClear} className="btn btn-light-secondary btn-sm">
                        Clear
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* Dash Card 2 - Product Table */}
        <div className="col-12 mt-1">
          <div className="card">
            <div className="card-body">
              <div id="DataTables_Table_2_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                <div className="row">
                  <div className="col-sm-12 px-0 table-responsive">
                    {loading ? (
                      <div className="d-flex justify-content-center align-items-center" style={{ height: "300px" }}>
                        <div className="spinner-border text-primary" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : productData.length === 0 ? (
                      <div className="d-flex justify-content-center align-items-center" style={{ height: "300px" }}>
                        <p>No records found</p>
                      </div>
                    ) : (
                      <table className="myDataTable table table-hover mb-0 nowrap dataTable no-footer dtr-inline" id="DataTables_Table_2">
                        <thead>
                          <tr>
                            <th>Vendor Name</th>
                            <th>Vendor Location</th>
                            <th>Product Name</th>
                            <th>Vendor Prices</th>
                            <th>Product SKU</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {productData?.map((item) => (
                            <tr key={item.id}>
                              <td>{item.vendorName}</td>
                              <td>{item.vendorLocation}</td>
                              <td>{item.productName}</td>
                              <td>{item.price}</td>
                              <td>{item.productSku}</td>
                              <td>{item.date}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>

              {/* Pagination Controls */}
              <div className="d-flex justify-content-between mt-3">
                <button className="btn btn-light-secondary" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                  Previous
                </button>
                <span>
                  Page {currentPage} of {totalPages}
                </span>
                <button className="btn btn-light-secondary" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductPriceList;
