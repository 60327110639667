import React, { useState, useEffect } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { Link, useNavigate, useLocation , useParams} from "react-router-dom";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addCustomerRequirement, fetchSku, getProductRequirements } from "../../services/customerService";
import { errorToast, successToast } from "../Toasts/Toasts";
import RequirementPDF from './RequirementPDF';
import { PDFDownloadLink } from '@react-pdf/renderer';

const CustomerAddRequirement = () => {
  const location = useLocation();
  const { customerName, customerLocation } = location.state || {};
  const {
    control,
    handleSubmit,
    register,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      items: [{ sku: "", price: "" }],
      date: new Date(),
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "items",
  });

  const [skuOptions, setSkuOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [inputValue2, setInputValue2] = useState("");

  const navigate = useNavigate();
  useEffect(() => {
    const fetchSkuOptions = async (query = "") => {
      try {
        const response = await fetchSku({ query });
        const options = response.map((item) => ({ value: item.value, label: item.label }));
        setSkuOptions(options);
      } catch (error) {
        console.error("Error fetching SKU options:", error);
      }
    };

    fetchSkuOptions(inputValue);
  }, [inputValue]);

  useEffect(() => {
    const fetchCustomerRequirements = async (query = "") => {
      try {
        const response = await getProductRequirements(customerRequirementId);
        setValue("items", response);
      } catch (error) {
        console.error("Error fetching SKU options:", error);
      }
    };

    fetchCustomerRequirements(inputValue2);
  }, [inputValue2]);


  const { customerId, customerRequirementId } = useParams();
  const handleSave = async (data) => {
    data.customerId = customerId;
    data.customerRequirementId = customerRequirementId;
    try {
      let response = await addCustomerRequirement(data);

      if (response?.success === true) {
        if (response?.data?.customerPrices != null) successToast(response?.message);

        /*if (response?.data?.alreadyExists?.length > 0) {
          response?.data?.alreadyExists.forEach((item) => {
            errorToast(`${item} already exists for the selected date`);
          });
        }*/

        navigate("/customerRequirements");
      }
    } catch (error) {
      errorToast("Something went wrong");
    }
  };

  const handleReset = () => {
    reset({
      items: [{ sku: "", price: "" }],
      date: new Date(),
    });
  };

  return (
    <>
      <div className="page-body px-0 py-lg-2 py-1 mt-0 mt-lg-3 ms-3">
        <div className="container-fluid p-0">
          <div className="row g-3">
            <div className="col-12">
              <div className="row-title px-2">
                <div>
                  <h6 className="fw-bold">Customer Requirement Management</h6>
                </div>
              </div>
            </div>

            {/* Dash Card 1 */}
            <div className="container">
              <div className="card">
                <div className="card-body">
             {/*    <div className="row" style={{align : 'right'}}>
                    <div>
                      <PDFDownloadLink document={<RequirementPDF  data={customerRequirementId}/>} fileName="Quotation_.pdf">
                      {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download now!')}
                    </PDFDownloadLink>
                    </div>
                </div> */}

                  <h5 className="card-title mb-2 pb-2">Add Requirements</h5>

                  <div className="row">
                    <div className="col-12">
                      <div className="col-6">
                        <p class="font-weight-bold">Customer Name: {customerName}</p>
                      </div>
                      <div className="col-6">
                        <p class="font-weight-bold">Customer Location: {customerLocation}</p>
                      </div>
                    </div>
                  </div>

                  <form onSubmit={handleSubmit(handleSave)}>
                    <div className="form-group">
                      <div className="form-group row">
                        <div className="col-lg-3 col-md-3 col-12 mb-3"> Requirement Date
                          <Controller name="date" control={control} render={({ field: { onChange, value, onBlur } }) => <DatePicker dateFormat="dd/MM/YYYY" selected={value} onChange={onChange} onBlur={onBlur} className="form-control" placeholderText="Select Date" style={{ padding: "0.25rem" }} />} />
                          {errors.date && <span className="text-danger">{errors.date.message}</span>}
                        </div>
                      </div>
                      {fields.map((field, index) => (
                        <div className="form-group row" key={field.id}>
                          <div className="col-lg-3 col-md-3 col-12 mb-3">
                            <Controller
                              name={`items.${index}.sku`}
                              control={control}
                              rules={{ required: "SKU is required" }}
                              render={({ field: { onChange, value, onBlur } }) => (
                                <Select
                                  options={skuOptions}
                                  onChange={(option) => {
                                    setValue(`items.${index}.sku`, option ? option.value : "");
                                  }}
                                  onBlur={onBlur}
                                  value={skuOptions.find((option) => option.value === value)}
                                  placeholder="Select SKU"
                                  onInputChange={(input) => setInputValue(input)}
                                  styles={{ control: (provided) => ({ ...provided, padding: "0.25rem" }) }}
                                />
                              )}
                            />
                            {errors.items?.[index]?.sku && <span className="text-danger">{errors.items[index].sku.message}</span>}
                          </div>
                          <div className="col-lg-3 col-md-3 col-12 mb-3">
                            <input type="number" className="form-control" placeholder="Quantity" {...register(`items.${index}.quantity`, { required: "Quantity is required" })} style={{ padding: "0.62rem" }} />
                            {errors.items?.[index]?.quantity && <span className="text-danger">{errors.items[index].quantity.message}</span>}
                          </div>
                          <div className="col-lg-3 col-md-3 col-12 mb-3">
                            <input type="number" className="form-control" placeholder="Price" {...register(`items.${index}.price`)} style={{ padding: "0.62rem" }} />
                            {errors.items?.[index]?.price && <span className="text-danger">{errors.items[index].price.message}</span>}
                          </div>

                          {fields.length > 1 && (
                            <div className="col-lg-2 col-md-2 col-6 mb-3">
                              <button type="button" onClick={() => remove(index)} className="btn btn-sm btn-icon btn-light-danger delete-row">
                                <span className="svg-icon svg-icon-1">
                                  <svg width={18} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect opacity="0.5" x="7.05025" y="15.5356" width={12} height={2} rx={1} transform="rotate(-45 7.05025 15.5356)" fill="currentColor" />
                                    <rect x="8.46447" y="7.05029" width={12} height={2} rx={1} transform="rotate(45 8.46447 7.05029)" fill="currentColor" />
                                  </svg>
                                </span>
                              </button>
                            </div>
                          )}
                        </div>
                      ))}

                      <div className="col-lg-3 col-md-3 col-12 mb-3">
                        <button type="button" className="btn btn-sm btn-light" onClick={() => append({ sku: "", price: "" })}>
                          <span className="svg-icon svg-icon-2">
                            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect opacity="0.5" x={11} y={18} width={12} height={2} rx={1} transform="rotate(-90 11 18)" fill="currentColor" />
                              <rect x={6} y={11} width={12} height={2} rx={1} fill="currentColor" />
                            </svg>
                          </span>
                          Add another
                        </button>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12">
                        <div className="d-flex justify-content-end">
                          <button type="submit" className="btn me-2" style={{ color: "#ffffff", backgroundColor: "#00ad9a9c" }}>
                            Save
                          </button>

                          <Link onClick={handleReset} className="btn btn-secondary ms-2">
                            Cancel
                          </Link>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerAddRequirement;
