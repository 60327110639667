import { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { getContacts } from "../../services/contactService";
import { errorToast } from "../Toasts/Toasts";
import { Button } from "react-bootstrap";
import ContactAddModal from "./ContactAddModal";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";


const ContactList = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [location, setLocation] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [contactType, setContactType] = useState("");
  const [source, setSource] = useState("");

  const [contactData, setContactData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    fetchContactData();
  }, [currentPage, pageSize]);

  const fetchContactData = useCallback(async () => {
    try {
      setLoading(true);
      const apiResponse = await getContacts({
        page: currentPage,
        size: pageSize,
      });

      setContactData(apiResponse?.rows || []);
      setTotalRecords(apiResponse?.count || 0);
    } catch (error) {
      errorToast("Something went wrong");
    } finally {
      setLoading(false);
    }
  }, [currentPage, pageSize]);

  const handleFilterData = useCallback(async () => {
    const filterParams = {
      ...(name?.trim() && { name: name.trim() }),
      ...(contactNo?.trim() && { contactNo: contactNo.trim() }),
      ...(location?.trim() && { location: location.trim() }),
      ...(companyName?.trim() && { companyName: companyName.trim() }),
      ...(contactType?.trim() && { contactType: contactType.trim() }),
      ...(source?.trim() && { source: source.trim() }),
    };

    try {
      setLoading(true);
      const apiResponse = await getContacts({
        ...filterParams,
        page: currentPage,
        size: pageSize,
      });

      setContactData(apiResponse?.rows || []);
      setTotalRecords(apiResponse?.count || 0);
    } catch (error) {
      errorToast("Something went wrong");
    } finally {
      setLoading(false);
    }
  }, [name, contactNo, location, companyName, contactType, source, currentPage, pageSize]);

  const handleFilterDataAndResetPage = useCallback(async () => {
    setCurrentPage(1); // Reset to page 1 when applying filters
    await handleFilterData();
  }, [handleFilterData]);

  const handleClear = async () => {
    setName("");
    setContactNo("");
    setLocation("");
    setCompanyName("");
    setContactType("");
    setSource("");
    setCurrentPage(1); // Reset to page 1 when clearing filters

    try {
      setLoading(true);
      const apiResponse = await getContacts({
        page: currentPage,
        size: pageSize,
      });
      setContactData(apiResponse?.rows || []);
      setTotalRecords(apiResponse?.count || 0);
    } catch (error) {
      errorToast("Something went wrong");
    } finally {
      setLoading(false); // Set loading to false once data is fetched
    }
  };

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const totalPages = Math.ceil(totalRecords / pageSize);
  
  return (
    <div className="page-body px-0 py-lg-2 py-1 mt-0 mt-lg-3 ms-3">
      <div className="container-fluid p-0">
        <div className="row g-3">
          {/* Contact Management header + Add button */}
          <div className="col-12">
            <div className="row-title px-2">
              <div>
                <h6 className="fw-bold">Contact Management</h6>
              </div>
              <Button variant="outline-success btn-sm" onClick={handleShowModal} className="mb-3">
                Add Contact
              </Button>
              <ContactAddModal show={showModal} handleClose={handleCloseModal} />
            </div>
            </div>
        
           {/* Dash Card 1 */}
           <div className="container">
            <div className="card">
              <div className="card-body">
                <form>
                  <div className="row">
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
                      <input type="text" className="form-control" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
                    </div>

                    <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
                      <input type="text" className="form-control" placeholder="Contact No" value={contactNo} onChange={(e) => setContactNo(e.target.value)} />
                    </div>

                    <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
                      <input type="text" className="form-control" placeholder="Location" value={location} onChange={(e) => setLocation(e.target.value)} />
                    </div>

                    <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
                      <input type="text" className="form-control" placeholder="Company Name" value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
                    </div>

                    <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
                      <select className="form-control" value={contactType} onChange={(e) => setContactType(e.target.value)}>
                        <option value="" disabled>
                        Select Contact type
                        </option>
                        <option value="Company">Company</option>
                        <option value="Contractor">Contractor</option>
                        <option value="Event planner">Event planner</option>
                        <option value="Individual">Individual</option>
                        <option value="Landscape Architect">Landscape Architect</option>
                        <option value="NGO">NGO</option>
                        <option value="Nursery">Nursery</option>
                      </select>
                    </div>

                    <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
                      <select className="form-control" value={source} onChange={(e) => setSource(e.target.value)}>
                        <option value="" disabled>
                          Select Contact source
                        </option>
                        <option value="Website">Website</option>
                        <option value="Indiamart">Indiamart</option>
                        <option value="JustDIal">JustDIal</option>
                        <option value="Data Mining - Google">Data Mining - Google</option>
                        <option value="Data Mining - Other">Data Mining - Other</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>

                    <div className="col-lg-3 col-md-4 col-sm-6 mb-3 d-flex justify-content-end">
                      <button type="button" onClick={handleFilterDataAndResetPage} className="btn btn-sm btn-light-success me-2">
                        Filter
                      </button>
                      <button type="button" onClick={handleClear} className="btn btn-light-secondary btn-sm">
                        Clear
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>


        {/* Contact Table */}
        <div className="col-12 mt-1">
          <div className="card">
            <div className="card-body">
              <div id="DataTables_Table_2_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                <div className="row">
                  <div className="col-sm-12 px-0 table-responsive">
                    {loading ? (
                      <div className="d-flex justify-content-center align-items-center" style={{ height: "300px" }}>
                        <div className="spinner-border text-primary" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : contactData.length === 0 ? (
                      <div className="d-flex justify-content-center align-items-center" style={{ height: "300px" }}>
                        <p>No records found</p>
                      </div>
                    ) : (
                      <table className="myDataTable table table-hover mb-0 nowrap dataTable no-footer dtr-inline" id="DataTables_Table_2">
                        <thead>
                          <tr>
                            <th>Contact Name</th>
                            <th>Contact Number</th>
                            <th>Location</th>
                            <th>Company Name</th>
                            <th>Type</th>
                            <th>Source</th>
                            <th>Created</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {contactData?.map((item) => (
                            <tr key={item.id}>
                              <td>{item.name}</td>
                              <td>{item.contactNo}</td>
                              <td>{item.location}</td>
                              <td>{item.companyName}</td>
                              <td>{item.contactType}</td>
                              <td>{item.source}</td>
                              <td>{item.createdAt}</td>
                              <td></td>                              
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>

              {/* Pagination Controls */}
              <div className="d-flex justify-content-between mt-3">
                <button className="btn btn-light-secondary" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                  Previous
                </button>
                <span>
                  Page {currentPage} of {totalPages}
                </span>
                <button className="btn btn-light-secondary" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactList;
