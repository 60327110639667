import apiConstants from "../constants/apiConstants";
import { axios } from "../utils/axios";

const baseUrl = process.env.REACT_APP_API_HOST;

export const getCustomers = async (data) => {
  try {
    const { page, size } = data;
    const url = `${baseUrl}/${apiConstants.GET_CUSTOMERS}?page=${page}&size=${size}`;

    const response = await axios.get(url, data);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addCustomer = async (data) => {
  try {
    const url = `${baseUrl}/${apiConstants.GET_CUSTOMERS}`;

    const response = await axios.post(url, data);

    return response;
  } catch (error) {
    throw error;
  }
};

export const addCustomerRequirement = async (data) => {
  try {
    const url = `${baseUrl}/${apiConstants.GET_CUSTOMER_REQUIREMENT}`;

    const response = await axios.post(url, data);

    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchSku = async (data) => {
  try {
    const { query } = data;

    const url = `${baseUrl}/${apiConstants.GET_SKU}?search=${query}`;

    const response = await axios.get(url);

    return response.data;
  } catch (error) {
    throw error;
  }
};


export const getProductRequirements = async (data) => {
  try {
    const customerRequirementId = Number(data)
    const url = `${baseUrl}/${apiConstants.GET_PRODUCT_REQUIREMENT}/`+ customerRequirementId;

    const response = await axios.get(url);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCustomerRequirements = async (filterObj) => {
  try {
    let url = `${baseUrl}/${apiConstants.GET_CUSTOMER_REQUIREMENT}`;

    const response = await axios.get(url, {
      params: filterObj,
    });

    return response.data;
  } catch (error) {
    throw error; 
  }
};


export const addProductPrice = async (data) => {
  try {
    const url = `${baseUrl}/${apiConstants.ADD_PRODUCT_PRICE}`;

    const response = await axios.post(url, data);

    return response;
  } catch (error) {
    throw error;
  }
};
