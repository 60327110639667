import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import {  getProductRequirements } from "../../services/customerService";
import React, { useState, useEffect } from "react";
import ItemsTable from "./ItemsTable";

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

const RequirementPDF = (props) => {
    const customerRequirementId = props.data


    // Initialise the state with an empty array
  const [products, setProducts] = useState([]);
  
  // Call `useEffect` with an empty dependency array
  // which will ensure it runs only once
  useEffect(() => {

    // Call the contactAPI function, wait for
    // the data and then update the state with the
    // product array
    async function getData() {
      const res = await getProductRequirements(customerRequirementId);
      setProducts(res);
      console.log(JSON.stringify(res))
      //[{"sku":"Neem-4","quantity":"34.00","price":"67.00"}]
    }
    getData();
  }, []);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
        <ItemsTable data={products} />


        </View>
      </Page>
    </Document>
  );
};

export default RequirementPDF;