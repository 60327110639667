import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { addProduct } from "../../services/productService";
import { errorToast, successToast } from "../Toasts/Toasts";

const ProductAdd = () => {
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      available: false,
    },
  });
  const [name, setName] = useState("");
  const [height, setHeight] = useState("");
  const navigate = useNavigate();
  // Update SKU based on name and height
  useEffect(() => {
    if (name && height) {
      let nameTrimmmed = name.trim().replace(/\s+/g, ' ').replaceAll(' ', '_')
      let heightTrimmmed = height.trim().replace(/\s+/g, ' ').replaceAll(' ', '_')
      const sku = `${nameTrimmmed}-${heightTrimmmed}`;
      setValue("sku", sku);
    }
  }, [name, height, setValue]);

  const filterPayload = (data) => {

    const filteredData = Object.fromEntries(
      Object.entries(data).filter(([key, value]) => {
        if (typeof value === "number") {
          // Remove NaN values but keep valid numbers
          return !isNaN(value);
        }
        // Keep other types of values if they are not null, undefined, or empty strings
        return value !== null && value !== "" && value !== undefined;
      })
    );

    return filteredData;
  };

  // Handle form submission
  const onSubmit = async (data,  addAnother) => {
    const filteredData = filterPayload(data);
    try {
      const response = await addProduct(filteredData);

      if (response) {
        successToast(response?.message);
        handleClear();
        if(!addAnother) {
          navigate("/product");
        }
      }
    } catch (error) {
      errorToast(error?.response?.data?.message || "Something went wrong.");
    }
  };

  const handleInputChange = (fieldName, value) => {
    setValue(fieldName, value);

    // Clear errors when input is valid
    if (value) {
      clearErrors(fieldName);
    }
  };

  const handleClear = () => {
    setHeight("");
    setName("");
    reset();
  };

  const handleSaveProduct = (data) => onSubmit(data, false);
  const handleSaveAndAddAnother = (data) => onSubmit(data, true); 
  return (
    <div className="page-body px-0 py-lg-2 py-1 mt-0 mt-lg-3 ms-3">
      <div className="container-fluid p-0">
        <div className="row g-3">
          {/* Product Management header + Add button */}
          <div className="col-12">
            <div className="row-title px-2">
              <div>
                <h6 className="fw-bold">Product Management</h6>
              </div>
            </div>
          </div>

          {/* Dash Card 1 */}
          <div className="container">
            <div className="card">
              <div className="card-body">
                <form onSubmit={handleSubmit(handleSaveProduct)}>
                  <div className="row">
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
                      <label>
                        Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                        {...register("name", { required: "Required" })}
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                          handleInputChange("name", e.target.value);
                        }}
                      />
                      {errors.name && <p className="text-danger">{errors.name.message}</p>}
                    </div>

                    <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
                      <label>Alternative Name</label>
                      <input type="text" className="form-control" placeholder="Alternative Name" {...register("alternativeName")} />
                    </div>

                    <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
                      <label>
                        SKU <span className="text-danger">*</span>
                      </label>
                      <input type="text" className="form-control" placeholder="SKU" {...register("sku")} />
                    </div>

                    <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
                      <label>
                        Height (in feet) <span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Height (in feet)"
                        {...register("height", { valueAsNumber: true, required: "Required" })}
                        value={height}
                        onChange={(e) => {
                          setHeight(e.target.value);
                          handleInputChange("height", e.target.value);
                        }}
                      />
                      {errors.height && <p className="text-danger">{errors.height.message}</p>}
                    </div>

                    <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
                      <label>
                        Category <span className="text-danger">*</span>
                      </label>
                      <select className="form-control" {...register("category", { required: "Required" })}>
                        <option value="">Select a category</option>
                        <option value="plants">Plants</option>
                        <option value="pots">Pots</option>
                        <option value="gifting">Gifting</option>
                        <option value="lawngrass">Lawn Grass</option>
                      </select>
                      {errors.category && <p className="text-danger">{errors.category.message}</p>}
                    </div>

                    <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
                      <label> Color</label>
                      <input type="text" className="form-control" placeholder="Colour" {...register("colour")} />
                    </div>

                    <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
                      <label>
                        Retail Price <span className="text-danger">*</span>
                      </label>
                      <input type="number" className="form-control" placeholder="Retail Price" {...register("retailPrice", { valueAsNumber: true , required: "Required" })} />
                      {errors.retailPrice && <p className="text-danger">{errors.retailPrice.message}</p>}
                    </div>

                    <div className="col-lg-3 col-md-4 col-sm-6 mb-3 mt-4">
                      <div className="form-check">
                        <input type="checkbox" className="form-check-input" id="available" {...register("available")} />
                        <label className="form-check-label">Available</label>
                      </div>
                    </div>

                    {/* <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
                      <label>
                        <input type="checkbox" {...register("available", { required: "Required" })} />
                        Available
                      </label>
                      {errors.available && <p className="text-danger">{errors.available.message}</p>}
                    </div> */}

                    <div className="col-12 my-3">
                      <hr className="dotted-divider" />
                    </div>

                    <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
                      <label>MOQ1</label>
                      <input type="number" className="form-control" placeholder="MOQ1" {...register("moq1", { valueAsNumber: true })} />
                    </div>

                    <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
                      <label>MOQ1 Price</label>
                      <input type="number" className="form-control" placeholder="MOQ1 Price" {...register("moq1Price", { valueAsNumber: true })} />
                    </div>

                    <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
                      <label>MOQ2</label>
                      <input type="number" className="form-control" placeholder="MOQ2" {...register("moq2", { valueAsNumber: true })} />
                    </div>

                    <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
                      <label>MOQ2 Price</label>
                      <input type="number" className="form-control" placeholder="MOQ2 Price" {...register("moq2Price", { valueAsNumber: true })} />
                    </div>

                    <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
                      <label>MOQ3</label>
                      <input type="number" className="form-control" placeholder="MOQ3" {...register("moq3", { valueAsNumber: true })} />
                    </div>

                    <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
                      <label>MOQ3 Price</label>
                      <input type="number" className="form-control" placeholder="MOQ3 Price" {...register("moq3Price", { valueAsNumber: true })} />
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-6 mb-3 mt-4 d-flex justify-content-end">
                      <button type="submit" className="btn me-2" style={{ color: "#ffffff", backgroundColor: "#00ad9a9c" }}>
                        Save
                      </button>
                      <button type="submit" className="btn me-2" style={{ color: "#ffffff", backgroundColor: "#00ad9a9c" }}    onClick={handleSubmit(handleSaveAndAddAnother)}>
                        Save and Add Another
                      </button>
                      <Link to="/product" className="btn btn-secondary ms-2">
                        Cancel
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductAdd;
