export default class ApiConstants {
    static GET_PRODUCTS = "api/products/filter"
    static ADD_PRODUCT = "api/products"
    static EDIT_PRODUCT = "api/products"
    static LOGIN = "login"
    static GET_VENDORS = "api/vendors"
    static ADD_VENDOR = "api/vendor"
    static GET_SKU = "api/vendors/fetch-sku"
    static ADD_PRODUCT_PRICE = "api/vendors/price"
    static GET_PRODUCTS_PRICE = "api/vendors/product"
    static GET_MASTER_DATA = "api/master-data"

    static GET_CUSTOMERS = "api/customers"
    static GET_CUSTOMER_REQUIREMENT = "api/customers/requirement"
    static GET_CUSTOMER_MASTER_DATA = "api/customer-master-data"
    static GET_PRODUCT_REQUIREMENT = "api/customers/productrequirements"
    
    static ADD_CONTACT = "api/contacts"
    static GET_CONTACTS = "api/contacts/filter"
}