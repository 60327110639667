import { Text, View, StyleSheet } from "@react-pdf/renderer";
import React, { Fragment, useState } from "react";

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
  description: {
    width: "60%",
  },
  xyz: {
    width: "40%",
  },
});

const TableRow = ({ items }) => {

  const rows = items.map((item) => (
    <View style={styles.row} key={item.sku}>
      <Text style={styles.description}>{item.sku}</Text>
      <Text style={styles.xyz}>{item.quantity}</Text>
      <Text style={styles.xyz}>{item.price}</Text>
    </View>
  ));
  
  return <Fragment>{rows}</Fragment>;
};

export default TableRow;