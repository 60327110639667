import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { editProduct } from "../../services/productService";
import { errorToast, successToast } from "../Toasts/Toasts";
import { axios } from "../../utils/axios";

const baseUrl = process.env.REACT_APP_API_HOST;

const ProductEdit = () => {
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      available: false,
    },
  });

  const { id } = useParams();
  const [name, setName] = useState("name");
  const [alternativeName, setAlternativeName] = useState("");
  const [sku, setSku] = useState("name-height");
  const [height, setHeight] = useState(0);
  const [retailPrice, setRetailPrice] = useState(0);
  const [category, setCategory] = useState("");
  const [colour, setColour] = useState("");
  const [available, setAvailable] = useState(false);
  const [moq1, setMoq1] = useState("");
  const [moq1Price, setMoq1Price] = useState("");

  const [moq2, setMoq2] = useState("");
  const [moq2Price, setMoq2Price] = useState("");

  const [moq3, setMoq3] = useState("");
  const [moq3Price, setMoq3Price] = useState("");

  const navigate = useNavigate();
  // Update SKU based on name and height
  useEffect(() => {
    if (name && height) {
      const sku = `${name}-${height}`;
      setValue("sku", sku);
    }
  }, [name, height, setValue]);

  useEffect(() => {
    // declare the async data fetching function
    const fetchData = async () => {
      // get the data from the api
      const data = await axios.get(`${baseUrl}/api/products/${id}`);
      setName(data.data.name);
      setHeight(data.data.height);
      setAlternativeName(data.data.alternativeName);
      setSku(data.data.sku);
      setRetailPrice(data.data.retailPrice);
      setCategory(data.data.category);
      setColour(data.data.colour);
      setAvailable(data.data.available);

      setMoq1(data.data.moq1);
      setMoq1Price(data.data.moq1Price);

      setMoq2(data.data.moq2);
      setMoq2Price(data.data.moq2Price);

      setMoq3(data.data.moq3);
      setMoq3Price(data.data.moq3Price);

      setValue("name", data.data.name);
      setValue("height", data.data.height);
      setValue("alternativeName", data.data.alternativeName);
      setValue("sku", data.data.sku);
      setValue("retailPrice", data.data.retailPrice);
      setValue("category", data.data.category);
      setValue("colour", data.data.colour);
      setValue("available", data.data.available);
      setValue("moq1", data.data.moq1);
      setValue("moq1Price", data.data.moq1Price);
      setValue("moq2", data.data.moq2);
      setValue("moq2Price", data.data.moq2Price);
      setValue("moq3", data.data.moq3);
      setValue("moq3Price", data.data.moq3Price);
    };

    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error);

    // cancel any future `setData`
    return;
  }, []);

  const filterPayload = (data) => {
    const filteredData = Object.fromEntries(
      Object.entries(data).filter(([key, value]) => {
        if (typeof value === "number") {
          // Remove NaN values but keep valid numbers
          return !isNaN(value);
        }
        // Keep other types of values if they are not null, undefined, or empty strings
        return value !== null && value !== "" && value !== undefined;
      })
    );

    return filteredData;
  };

  // Handle form submission
  const onSubmit = async (data) => {
    const filteredData = filterPayload(data);
    try {
      const response = await editProduct(filteredData);

      if (response) {
        successToast(response?.message);
        handleData(response.product);
      }
    } catch (error) {
      errorToast(error?.response?.data?.message || "Something went wrong.");
    }
  };

  const handleInputChange = (fieldName, value) => {
    setValue(fieldName, value);
    // Clear errors when input is valid
    if (value) {
      clearErrors(fieldName);
    }
  };

  const handleData = (product) => {
    setName(product.name);
    setHeight(product.height);
    setAlternativeName(product.alternativeName);
    setSku(product.sku);
    setRetailPrice(product.retailPrice);
    setCategory(product.category);
    setColour(product.colour);
    setAvailable(product.available);
    setMoq1(product.moq1);
    setMoq1Price(product.moq1Price);

    setMoq2(product.moq2);
    setMoq2Price(product.moq2Price);

    setMoq3(product.moq3);
    setMoq3Price(product.moq3Price);
  };

  const handleClear = () => {
    reset();
  };

  const handleSaveProduct = (data) => onSubmit(data, false);
  return (
    <div className="page-body px-0 py-lg-2 py-1 mt-0 mt-lg-3 ms-3">
      <div className="container-fluid p-0">
        <div className="row g-3">
          {/* Product Management header + Add button */}
          <div className="col-12">
            <div className="row-title px-2">
              <div>
                <h6 className="fw-bold">Product Management</h6>
              </div>
            </div>
          </div>
          {/* Dash Card 1 */}
          <div className="container">
            <div className="card">
              <div className="card-body">
                <form onSubmit={handleSubmit(handleSaveProduct)}>
                  <div className="row">
                    <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
                      <label>
                        Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        placeholder="Name"
                        value={name}
                        readOnly
                        {...register("name", { required: "Required" })}
                        onChange={(e) => {
                          setName(e.target.value);
                          handleInputChange("name", e.target.value);
                        }}
                      />
                      {errors.name && <p className="text-danger">{errors.name.message}</p>}
                    </div>

                    <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
                      <label>Alternative Name</label>
                      <input
                        type="text"
                        name="alternativeName"
                        value={alternativeName}
                        className="form-control"
                        placeholder="Alternative Name"
                        {...register("alternativeName")}
                        onChange={(e) => {
                          setAlternativeName(e.target.value);
                          handleInputChange("alternativeName", e.target.value);
                        }}
                      />
                    </div>

                    <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
                      <label>
                        SKU <span className="text-danger">*</span>
                      </label>
                      <input type="text" className="form-control" name="sku" value={sku} placeholder="SKU" {...register("sku")} readOnly />
                    </div>

                    <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
                      <label>
                        Height (in feet) <span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        name="height"
                        value={height}
                        readOnly
                        placeholder="Height (in feet)"
                        {...register("height", { valueAsNumber: true, required: "Required" })}
                        onChange={(e) => {
                          setHeight(e.target.value);
                          handleInputChange("height", e.target.value);
                        }}
                      />
                      {errors.height && <p className="text-danger">{errors.height.message}</p>}
                    </div>

                    <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
                      <label>
                        Category <span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-control"
                        value={category}
                        {...register("category", { required: "Required" })}
                        onChange={(e) => {
                          setCategory(e.target.value);
                          handleInputChange("category", e.target.value);
                        }}
                      >
                        <option value="">Select a category</option>
                        <option value="plants">Plants</option>
                        <option value="pots">Pots</option>
                        <option value="gifting">Gifting</option>
                        <option value="lawngrass">Lawn Grass</option>
                      </select>
                      {errors.category && <p className="text-danger">{errors.category.message}</p>}
                    </div>

                    <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
                      <label> Colour</label>
                      <input
                        type="text"
                        className="form-control"
                        value={colour}
                        placeholder="Colour"
                        {...register("colour")}
                        onChange={(e) => {
                          setColour(e.target.value);
                          handleInputChange("colour", e.target.value);
                        }}
                      />
                    </div>

                    <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
                      <label>
                        Retail Price <span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        name="retailPrice"
                        value={retailPrice}
                        placeholder="Retail Price"
                        {...register("retailPrice", { valueAsNumber: true, required: "Required" })}
                        onChange={(e) => {
                          setRetailPrice(e.target.value);
                          handleInputChange("retailPrice", e.target.value);
                        }}
                      />
                      {errors.retailPrice && <p className="text-danger">{errors.retailPrice.message}</p>}
                    </div>

                    <div className="col-lg-3 col-md-4 col-sm-6 mb-3 mt-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          checked={available}
                          id="available"
                          {...register("available")}
                          onChange={(e) => {
                            setAvailable(e.target.checked);
                            handleInputChange("available", e.target.value);
                          }}
                        />
                        <label className="form-check-label">Available</label>
                      </div>
                    </div>

                    <div className="col-12 my-3">
                      <hr className="dotted-divider" />
                    </div>

                    <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
                      <label>MOQ1</label>
                      <input
                        type="number"
                        className="form-control"
                        value={moq1}
                        placeholder="MOQ1"
                        {...register("moq1", { valueAsNumber: true })}
                        onChange={(e) => {
                          setMoq1(e.target.value);
                          handleInputChange("moq1", e.target.value);
                        }}
                      />
                    </div>

                    <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
                      <label>MOQ1 Price</label>
                      <input
                        type="number"
                        className="form-control"
                        value={moq1Price}
                        placeholder="MOQ1 Price"
                        {...register("moq1Price", { valueAsNumber: true })}
                        onChange={(e) => {
                          setMoq1Price(e.target.value);
                          handleInputChange("moq1Price", e.target.value);
                        }}
                      />
                    </div>

                    <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
                      <label>MOQ2</label>
                      <input
                        type="number"
                        className="form-control"
                        value={moq2}
                        placeholder="MOQ2"
                        {...register("moq2", { valueAsNumber: true })}
                        onChange={(e) => {
                          setMoq2(e.target.value);
                          handleInputChange("moq2", e.target.value);
                        }}
                      />
                    </div>

                    <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
                      <label>MOQ2 Price</label>
                      <input
                        type="number"
                        className="form-control"
                        value={moq2Price}
                        placeholder="MOQ2 Price"
                        {...register("moq2Price", { valueAsNumber: true })}
                        onChange={(e) => {
                          setMoq2Price(e.target.value);
                          handleInputChange("moq2Price", e.target.value);
                        }}
                      />
                    </div>

                    <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
                      <label>MOQ3</label>
                      <input
                        type="number"
                        className="form-control"
                        value={moq3}
                        placeholder="MOQ3"
                        {...register("moq3", { valueAsNumber: true })}
                        onChange={(e) => {
                          setMoq3(e.target.value);
                          handleInputChange("moq3", e.target.value);
                        }}
                      />
                    </div>

                    <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
                      <label>MOQ3 Price</label>
                      <input
                        type="number"
                        className="form-control"
                        value={moq3Price}
                        placeholder="MOQ3 Price"
                        {...register("moq3Price", { valueAsNumber: true })}
                        onChange={(e) => {
                          setMoq3Price(e.target.value);
                          handleInputChange("moq3Price", e.target.value);
                        }}
                      />
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-6 mb-3 mt-4 d-flex justify-content-end">
                      <button type="submit" className="btn me-2" style={{ color: "#ffffff", backgroundColor: "#00ad9a9c" }}>
                        Update
                      </button>
                      <Link onClick={handleClear} className="btn btn-secondary ms-2">
                        Cancel
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductEdit;
