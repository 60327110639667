import apiConstants from "../constants/apiConstants";
import { axios } from "../utils/axios";

const baseUrl = process.env.REACT_APP_API_HOST;

export const productFilter = async (filterObj) => {
  try {
    let url = `${baseUrl}/${apiConstants.GET_PRODUCTS}`;

    const response = await axios.post(url, filterObj);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addProduct = async (productObj) => {
  try {
    let url = `${baseUrl}/${apiConstants.ADD_PRODUCT}`;

    const response = await axios.post(url, productObj);

    return response;
  } catch (error) {
    throw error;
  }
};

export const editProduct = async (productObj) => {
  try {
    let url = `${baseUrl}/${apiConstants.EDIT_PRODUCT}`;

    const response = await axios.put(url, productObj);

    return response;
  } catch (error) {
    throw error;
  }
};

export const getProductsPrice = async (filterObj) => {
  try {
    let url = `${baseUrl}/${apiConstants.GET_PRODUCTS_PRICE}`;

    const response = await axios.get(url, {
      params: filterObj,
    });

    return response.data;
  } catch (error) {
    throw error; 
  }
};
